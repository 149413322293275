// gsap.registerPlugin(ScrollTrigger);
//
// $(document).ready(function () {
//
//     // --------------------
//     // soft scroll function
//     // --------------------
//     var softscroll = function (clickelement) {
//         clickelement.click(function (event) {
//             var indexHref = $(this).attr("href");
//             var indexHash = indexHref.split('#')[1];
//             var $indexSelector = $("#" + indexHash);
//
//             if ($indexSelector.length > 0) {
//                 event.preventDefault();
//                 $('html, body').animate({
//                     scrollTop: $indexSelector.offset().top
//                 }, 850);
//
//                 // close navigation after selection
//                 if (checkMobileState == "block") {
//                     $('.mobile-nav').removeClass("act");
//                     $('.nav-fixed').fadeOut(300);
//                 }
//             }
//         });
//     }
//
//     // softscroll elements (navigation, actionboxes ...)
//     softscroll($('.softscroll'));
//
// });

// --------------------
// LazyLoading
// --------------------
new LazyLoad({});

// --------------------
// CHANGE COLOR SCHEME ON CHANGE
// --------------------

// color scheme
const COLOR_SCHEMES = ['dark', 'light'];

function removeColorSchemeClasses(identifier, $target) {
    COLOR_SCHEMES.forEach(function (colorScheme) {
        $target.removeClass(identifier + 'color-scheme--' + colorScheme);
    })
}

function addColorSchemeClass(identifier, colorScheme, $target) {
    $target.addClass(identifier + 'color-scheme--' + colorScheme);
}

// get all elements with the class "js-section
let sections = document.querySelectorAll('.js-section');
sections.forEach(section => {
    let id = section.getAttribute('id');
    gsap.to(`#${id}`, {
        scrollTrigger: {
            trigger: `#${id}`, // start the animation when ".box" enters the viewport (once)
            start: "top top", // when the top of the trigger hits the top of the viewport
            end: "100%",
            onToggle: (self) => {
                if (self.isActive) {
                    let $target = $('body');
                    let colorScheme = self.trigger.getAttribute('data-color-scheme');
                    let identifier = 'header-';
                    removeColorSchemeClasses(identifier, $target);
                    addColorSchemeClass(identifier, colorScheme, $target);
                }
            }
        }
    });
});

// --------------------
// Responsive Video
// --------------------
const BREAKPOINTS = {
    MOBILE: 442,
    TABLET: 780,
    DESKTOP: 1420,
}

function updateVideoSrc(video) {
    let srcDesktop = video.getAttribute('data-src-desktop');
    let srcDesktopFallback = video.getAttribute('data-src-desktop-fallback');
    let srcSmartphone = video.getAttribute('data-src-smartphone');
    let srcSmartphoneFallback = video.getAttribute('data-src-smartphone-fallback');

    let videoSrc = video.getAttribute('src');
    let videoType = video.getAttribute('type');

    if (video.canPlayType('video/webm; codecs="vp8, vorbis"') === "probably") {
        if (window.innerWidth <= BREAKPOINTS.TABLET) {
            if (srcSmartphone) {
                videoSrc = srcSmartphone;
                videoType = video.getAttribute('data-type-smartphone');
            } else {
                videoSrc = srcSmartphoneFallback;
                videoType = video.getAttribute('data-type-smartphone-fallback');
            }
        } else {
            if (srcDesktop) {
                videoSrc = srcDesktop;
                videoType = video.getAttribute('data-type-desktop');
            } else {
                videoSrc = srcDesktopFallback;
                videoType = video.getAttribute('data-type-desktop-fallback');
            }
        }
    } else {
        if (window.innerWidth <= BREAKPOINTS.MOBILE) {
            videoSrc = srcSmartphoneFallback;
            videoType = video.getAttribute('data-type-smartphone-fallback');
        } else {
            videoSrc = srcDesktopFallback;
            videoType = video.getAttribute('data-type-desktop-fallback');
        }
    }

    if (videoSrc === null || videoType === null) {
        return;
    }

    if (video.getAttribute('src') !== videoSrc) {
        video.setAttribute('src', videoSrc);
        if (!video.classList.contains('video-initialized')) {
            video.classList.add('video-initialized');
        }
    }

    if (video.getAttribute('type') !== videoType) {
        video.setAttribute('type', videoType);
    }

}

// update video src on load
document.querySelectorAll('.js-responsive-video').forEach(video => {
    if (video.closest('header')) {
        // load video in header area on load of page
        updateVideoSrc(video);
    } else {
        // add section observer for video

        let observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    if (!video.classList.contains('video-initialized')) {
                        updateVideoSrc(video);
                    }
                }
            });
        });
        observer.observe(video);
    }
});
// update video src on resize
window.addEventListener("resize", _ => {
    document.querySelectorAll('.js-responsive-video').forEach(video => {
        if (video.classList.contains('video-initialized')) {
            updateVideoSrc(video);
        }
    });
});

// --------------------
// Responsive Link
// --------------------
// update video src on load
function updateLinkHref(link) {
    let hrefDesktop = link.getAttribute('data-href');
    let hrefSmartphone = link.getAttribute('data-href-smartphone');
    if (window.innerWidth <= BREAKPOINTS.DESKTOP) {
        if (link.href !== hrefSmartphone) {
            link.href = hrefSmartphone;
        }
    } else {
        if (link.href !== hrefDesktop) {
            link.href = hrefDesktop;
        }
    }

}

document.querySelectorAll('.js-responsive-link').forEach(link => {
    updateLinkHref(link);
});
// update link href on resize
window.addEventListener("resize", _ => {
    document.querySelectorAll('.js-responsive-link').forEach(link => {
        updateLinkHref(link);
    });
});

// --------------------
// hide image an play video on hover
// --------------------
document.querySelectorAll('.js-play-video-on-hover').forEach(wrapperEl => {
    wrapperEl.addEventListener('mouseenter', function () {
        this.querySelector('video').play();
    });
});


// --------------------
// Swiper Slider
// --------------------
const swiper = new Swiper('.swiper', {
    // Optional parameters
    loop: true,

    // If we need pagination
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },

    // Navigation arrows
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },

    // And if we need scrollbar
    scrollbar: {
        el: '.swiper-scrollbar',
    },
});


// --------------------
// Drag Scroll for Overflow Scroll Slider
// --------------------
const pointerScroll = (elem) => {

    let isDrag = false;

    const dragStart = () => isDrag = true;
    const dragEnd = () => isDrag = false;
    const drag = (ev) => isDrag && (elem.scrollLeft -= ev.movementX);

    elem.addEventListener("pointerdown", dragStart);
    addEventListener("pointerup", dragEnd);
    addEventListener("pointermove", drag);
};

document.querySelectorAll(".overflow-slider").forEach(pointerScroll);


//Overflow Scroll Slider Arrow onclick
function scrollRightOnArrowClick() {
    const arrows = document.querySelectorAll('.js-overflow-slider-arrow');
    const slider = document.querySelector('.overflow-slider');

    arrows.forEach(arrow => {
        arrow.addEventListener('click', () => {
            slider.scrollBy({
                left: slider.clientWidth, // scroll the slider width
                behavior: 'smooth' // animate smoothly
            });
        });
    });
}

scrollRightOnArrowClick();


function scrollOnArrowClick() {
    const arrowRight = document.querySelector('.js-overflow-slider-arrow-right');
    const arrowLeft = document.querySelector('.js-overflow-slider-arrow-left');
    const slider = document.querySelector('.overflow-slider');

    arrowRight.addEventListener('click', () => {
        slider.scrollBy({
            left: slider.clientWidth, // scroll the slider width
            behavior: 'smooth' // animate smoothly
        });
    });

    arrowLeft.addEventListener('click', () => {
        slider.scrollBy({
            left: -slider.clientWidth, // Negative value for opposite direction
            behavior: 'smooth' // animate smoothly
        });
    });

}

scrollOnArrowClick();



// script to hide the arrows, when the overflow content is on end
$(document).ready(function() {
    $(".overflow-slider").on("scroll", function () {
        var cur = $(this).scrollLeft();
        if (cur == 0) {
            $('.overflow-slider__arrow--container').addClass('js-arrow-right').removeClass('js-arrow-left');
        }
        else {
            var max = $(this)[0].scrollWidth - $(this).parent().width();
            if (cur == max) {
                $('.overflow-slider__arrow--container').addClass('js-arrow-left').removeClass('js-arrow-right');
            } else {
                $('.overflow-slider__arrow--container').addClass('js-arrow-right js-arrow-left');
            }
        }
    });
    $(".scroll-container").trigger("scroll");
});



// --------------------
// tab element
// --------------------
const tabNavigation = document.querySelectorAll('.tab-navigation__item');
const tabPanels = document.querySelectorAll('.tab-panel');

tabNavigation.forEach(tab => {
    tab.addEventListener('click', () => {
        const targetTabPanel = document.getElementById(tab.dataset.target);

        tabNavigation.forEach(otherTab => otherTab.classList.remove('active'));
        tabPanels.forEach(panel => panel.classList.remove('active'));

        tab.classList.add('active');
        targetTabPanel.classList.add('active');
    });
});

// --------------------
// center links
// --------------------
/**
 * center a link inside a long horizontal list
 * @param link
 * @param linksWrapperSelector
 * @param compvareFunction
 */
function centerTabLink(link, linksWrapperSelector, compvareFunction) {
    var $link = link instanceof jQuery ? link : $(link);
    var tabLinkWidth = $link.outerWidth(),
        windowWidth = $(window).width(),
        currentScrollLeft = $(linksWrapperSelector).scrollLeft();
    var targetOffset = windowWidth / 2 - tabLinkWidth / 2;
    var tabOffsetLeft = $link.offset().left + currentScrollLeft;
    var scrollLeftOffset = tabOffsetLeft - targetOffset;
    $(linksWrapperSelector).animate({
        'scrollLeft': scrollLeftOffset
    }, 400, function () {
        // Animation compvare.
        if (compvareFunction) {
            compvareFunction();
        }
    });

}

/**
 * center link inside of .js-center-links-container on click
 */
$(document).ready(function () {

    $('.js-center-links-trigger').on('click', function () {
        centerTabLink($(this), '.js-center-links-container', null);
    })
});


// --------------------
// show more element
// --------------------
// Select all elements that you want to be clickable
var clickableElements = document.querySelectorAll('.js-clickable');

// Attach a click event listener to each clickable element
for (var i = 0; i < clickableElements.length; i++) {
    clickableElements[i].addEventListener('click', function (event) {
        // Get the clicked element itself
        const clickedElement = event.currentTarget;

        // Toggle the 'active' class on the clicked element
        clickedElement.classList.toggle('active');

        // Find the next element with the class "show-more-element__content" that is a sibling of the clicked element
        let nextContentElement = clickedElement.nextElementSibling;
        if (clickedElement.closest('.show-more-element__wrapper')) {
            if (clickedElement.closest('.show-more-element__wrapper').classList.contains('show-more-element--position-below')) {
                nextContentElement = clickedElement.previousElementSibling;
            }
        }
        if (nextContentElement && nextContentElement.classList.contains('show-more-element__content')) {
            nextContentElement.classList.toggle('active');
        }
    });
}

// --------------------
// open/close contact form
// --------------------
function toggleContactForm() {
    // Find the target contact form container using querySelector
    const contactFormContainer = document.querySelector('.contact-form__container');

    // scroll to top of form
    contactFormContainer.scrollTop = 0;

    // Toggle the "active" class efficiently using classList.toggle()
    contactFormContainer.classList.toggle('active');
    document.querySelector('body').classList.toggle('contact-form-is-active');

    // find the main target form button (right up corner) and change text in it
    const jsMainFormButton = document.querySelector('.js-main-form-button');
    jsMainFormButton.classList.toggle('active');


    // exchange the content of the primary CTA for the contact-form when closed and opend in all 3 languages
    const textContent = jsMainFormButton.classList.contains('active')
        ? (jsMainFormButton.classList.contains('german') ? 'Schließen' : (jsMainFormButton.classList.contains('english') ? 'Close' : 'Fermer'))
        : (jsMainFormButton.classList.contains('german') ? 'Anfragen' : (jsMainFormButton.classList.contains('english') ? 'Inquiry' : 'Demande'));

    jsMainFormButton.textContent = textContent;
}

function prefillForm(preFillData) {

    for (var index in preFillData) {
        let formElement = document.getElementById(index);

        if (!formElement) {
            console.error('Form element with id ' + index + ' not found');
        }

        let type = formElement.getAttribute('type');
        switch (type) {
            case 'text':
            case 'email':
            case 'date':
            case 'number':
            case 'tel':
                formElement.value = preFillData[index];
                break;
            case 'radio':
                formElement.checked = true;
                break;
            case 'checkbox':
                formElement.checked = true;
                break;
            default:
                console.error('Unknown type of form element');
        }
    }
}

// Add event listener to all elements with class "js-open-form"
const openFormElements = document.querySelectorAll('.js-open-form');
openFormElements.forEach(element => {
    element.addEventListener('click', function () {
        if (element.classList.contains('js-open-form')) {
            toggleContactForm();
        }

        let preFillData = element.getAttribute('data-prefill');
        if (preFillData) {
            preFillData = JSON.parse(preFillData);
            prefillForm(preFillData);
        }
    });
});

// open form based on parameter in url
if (window.location.href.includes('open-form=true')) {
    toggleContactForm();
}

// Add event listener to all elements with class "js-open-form"
const formInputElements = document.querySelectorAll('input');
formInputElements.forEach(element => {
    if ($(element).attr('type') === 'text' ||
        $(element).attr('type') === 'email' ||
        $(element).attr('type') === 'date' ||
        $(element).attr('type') === 'number' ||
        $(element).attr('type') === 'tel') {
        element.addEventListener('focus', function () {
            if ($(this).closest('.form-group').hasClass('is-active')) {
                return;
            }
            $(this).closest('.form-group').addClass('is-active');
        });
    }
});

// --------------------
// scaling section
// --------------------
const scalingSections = document.querySelectorAll('.scaling-section');

const observerOptions = {
    root: null,
    rootMargin: '0px 0px -100px 0px',
    threshold: 0.8 // Observe when element is 80% visible
};

const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            entry.target.classList.add('js-in-viewport');
        } else {
            entry.target.classList.remove('js-in-viewport');
        }
    });
});

scalingSections.forEach(section => observer.observe(section));


// --------------------
// GLightbox
// --------------------
const lightbox = GLightbox({
    touchNavigation: true,
    loop: true,
    autoplayVideos: true
});

// ------------------------------------
// --- Image Change Section | START ---
// ------------------------------------

function removeJsDotClasses(element) {
    // Alle Klassen des Elements abrufen
    const classes = element.classList;

    // Regex-Muster erstellen, das mit "js-dot-" beginnt und beliebige Ziffern am Ende zulässt
    const regex = /^js-dot-\d+$/;

    // Array mit allen Klassen erstellen, die entfernt werden sollen
    const classesToRemove = [];

    // Klassen durchlaufen und diejenigen mit dem Muster in das Array "classesToRemove" pushen
    for (const className of classes) {
        if (regex.test(className)) {
            classesToRemove.push(className);
        }
    }

    // Gefundene Klassen vom Element entfernen
    for (const className of classesToRemove) {
        classes.remove(className);
    }
}

function setTriggerActive($trigger) {
    $(".js-dot-trigger").removeClass("js-active");
    $trigger.addClass("js-active");
}

function setSectionActive($section, id) {
    removeJsDotClasses($section[0]);
    $section.addClass(id);
}

function setBackgroundActiveOfId($background) {
    $(".js-background").removeClass("js-active");
    $background.addClass("js-active");
}

// Füge allen Elementen mit der Klasse "dot" einen Click-Handler hinzu
$(".js-dot-trigger").click(function () {
    let $trigger = $(this);
    let id = $trigger.attr('data-id');
    let $section = $trigger.closest('.img-change-section');
    let $background = $section.find(`.js-background[data-id='${id}']`);
    setSectionActive($section, id);
    setBackgroundActiveOfId($background);
    setTriggerActive($trigger);
});

// ------------------------------------
// --- Image Change Section | END ---
// ------------------------------------

// --------------------
// AUTOPLAY VIDEO
// --------------------
// const autoplayVideoEls = document.querySelectorAll('.js-autoplay-video');
// let scrollPosition = 0;
//
// autoplayVideoEls.forEach(videoEl => {
//     // example : https://codepen.io/osublake/pen/KKzgqKr
//     const observer = new IntersectionObserver((entries, observer) => {
//         entries.forEach(entry => {
//             if (entry.isIntersecting) {
//                 videoEl.play();
//             } else {
//                 stopVideo();
//             }
//         });
//     });
//
//     videoEl.addEventListener('canplaythrough', function () {
//         if (videoEl.classList.contains('canplaythrough')) {
//             return;
//         }
//         videoEl.classList.add('canplaythrough');
//         observer.observe(videoEl);
//     });
//
//     function stopVideo() {
//         if (videoEl.classList.contains('is-playing')) {
//             videoEl.pause();
//             videoEl.currentTime = 0;
//             videoEl.classList.remove('is-playing');
//         }
//     }
// })

// console.clear();
// /* The encoding is super important here to enable frame-by-frame scrubbing. */
//
// // ffmpeg -i ~/Downloads/Toshiba\ video/original.mov -movflags faststart -vcodec libx264 -crf 23 -g 1 -pix_fmt yuv420p output.mp4
// // ffmpeg -i ~/Downloads/Toshiba\ video/original.mov -vf scale=960:-1 -movflags faststart -vcodec libx264 -crf 20 -g 1 -pix_fmt yuv420p output_960.mp4
//
// const video = document.querySelector(".autoplay-video__video");
// let src = video.currentSrc || video.src;
//
// /* Make sure the video is 'activated' on iOS */
// function once(el, event, fn, opts) {
//     var onceFn = function (e) {
//         el.removeEventListener(event, onceFn);
//         fn.apply(this, arguments);
//     };
//     el.addEventListener(event, onceFn, opts);
//     return onceFn;
// }
//
// once(document.documentElement, "touchstart", function (e) {
//     video.play();
//     video.pause();
// });
//
// /* ---------------------------------- */
// /* Scroll Control! */
//
// gsap.registerPlugin(ScrollTrigger);
//
// let tl = gsap.timeline({
//     defaults: { duration: 1 },
//     scrollTrigger: {
//         trigger: "autoplay-video",
//         start: "top top",
//         end: "bottom bottom",
//         scrub: true
//     }
// });
//
// once(video, "loadedmetadata", () => {
//     tl.fromTo(
//         video,
//         {
//             currentTime: 0
//         },
//         {
//             currentTime: video.duration || 1
//         }
//     );
// });
//
// /* When first coded, the Blobbing was important to ensure the browser wasn't dropping previously played segments, but it doesn't seem to be a problem now. Possibly based on memory availability? */
// setTimeout(function () {
//     if (window["fetch"]) {
//         fetch(src)
//             .then((response) => response.blob())
//             .then((response) => {
//                 var blobURL = URL.createObjectURL(response);
//
//                 var t = video.currentTime;
//                 once(document.documentElement, "touchstart", function (e) {
//                     video.play();
//                     video.pause();
//                 });
//
//                 video.setAttribute("src", blobURL);
//                 video.currentTime = t + 0.000001;
//             });
//     }
// }, 1000);

/* ---------------------------------- */


// ------------------------------------
// --- AUTOPLAY VIDEO | END ---
// ------------------------------------




// language select trigger
function languageSelectMenuToggle() {
    const jsLanguageSelect = document.querySelector(".js-language-select");
    const jsLanguageSelectMenu = document.querySelector(".js-language-select-menu");

    jsLanguageSelect.addEventListener("click", () => {
        jsLanguageSelectMenu.classList.toggle("active");
    });
}

// function call
languageSelectMenuToggle();













